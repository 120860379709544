import cx from 'classnames';
import { ReactNode, useState } from 'react';
import Select, { FormatOptionLabelMeta, GroupBase } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import css from './index.module.css';

export type SelectOptionType = {
  value: string;
  label: string;
  icon?: any;
};

export enum SelectType {
  Blockchain = 'blockchain',
}

export type SelectPropsType = {
  options: SelectOptionType[];
  isSearchable?: boolean;
  onChange: (value: string) => void;
  defaultValue?: string;
  name: string;
  className?: string;
  customLabel?:
    | ((
        data: SelectOptionType,
        formatOptionLabelMeta: FormatOptionLabelMeta<SelectOptionType>
      ) => ReactNode)
    | undefined;
  ref?: any;
};

type BaseSelectPropsType = SelectPropsType & {
  components?: Partial<SelectComponents<SelectOptionType, boolean, GroupBase<SelectOptionType>>>;
};

export const BaseSelect = ({
  options,
  isSearchable = false,
  onChange,
  defaultValue,
  name,
  className,
  ref,
  components,
  customLabel,
}: BaseSelectPropsType) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleChange = (option: unknown) => {
    const data = option as SelectOptionType;
    onChange(data.value);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      isSearchable={isSearchable}
      defaultValue={options?.find((el) => el.value === defaultValue)}
      ref={ref}
      menuPlacement="auto"
      className={cx(css.select, className)}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      name={name}
      components={components}
      styles={{
        dropdownIndicator: (base) => ({
          ...base,
          transform: `rotate(${isMenuOpen ? 180 : 0}deg)`,
          color: 'var(--onSurface-primary)',
          padding: 0,

          ':hover': {
            color: 'var(--onSurface-primary)',
          },
        }),
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          minHeight: '32px',
          borderRadius: '0',
        }),
        option: () => ({}),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
        }),
        singleValue: (base) => ({
          ...base,
          margin: 0,
        }),
      }}
      formatOptionLabel={customLabel}
    />
  );
};
