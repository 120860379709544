import { components, OptionProps } from 'react-select';
import { CheckmarkIcon } from 'shared/ui/atoms/icons/CheckmarkIcon';

import { SelectOptionType } from '../../../..';

import css from './index.module.css';

export const BlockchainOption = ({
  children,
  isSelected,
  ...props
}: OptionProps<SelectOptionType>) => (
  <components.Option isSelected={isSelected} className={css.option} {...props}>
    {children}
    {isSelected ? <CheckmarkIcon /> : null}
  </components.Option>
);
