import { ComponentProps } from 'react';

export const LogoIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="127"
    height="24"
    viewBox="0 0 127 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15692_141385)">
      <path
        d="M8.37685 20.0737C6.41665 20.0737 4.85461 19.4473 3.6601 18.1793C2.48092 16.9114 1.88367 15.3226 1.88367 13.3977C1.88367 11.4728 2.4656 9.884 3.62948 8.61601C4.77804 7.36331 6.30945 6.72168 8.2084 6.72168C9.80107 6.72168 11.1028 7.14943 12.0982 7.98966C13.1089 8.82989 13.7215 9.9451 13.9512 11.3659H10.674C10.5515 10.7853 10.2911 10.327 9.87764 9.96038C9.46416 9.59373 8.95879 9.42569 8.34623 9.42569C7.36612 9.42569 6.60042 9.77706 6.07974 10.4798C5.55906 11.1825 5.2834 12.1603 5.2834 13.413C5.2834 14.6504 5.54374 15.6128 6.04911 16.3308C6.55447 17.0489 7.30487 17.4002 8.30028 17.4002C9.77044 17.4002 10.6127 16.6822 10.8424 15.2615H14.0737C13.9512 16.6517 13.3846 17.8127 12.3738 18.714C11.3478 19.6154 10.0155 20.0737 8.37685 20.0737Z"
        fill="black"
      />
      <path
        d="M26.2331 18.1641C24.9927 19.4321 23.4153 20.0737 21.4704 20.0737C19.5255 20.0737 17.9482 19.4321 16.7077 18.1641C15.4673 16.8961 14.8547 15.3073 14.8547 13.3977C14.8547 11.4881 15.4673 9.91455 16.7077 8.63129C17.9482 7.36331 19.5255 6.72168 21.4704 6.72168C23.4153 6.72168 24.9927 7.36331 26.2331 8.63129C27.4736 9.89927 28.0861 11.4881 28.0861 13.3977C28.0861 15.3073 27.4736 16.8961 26.2331 18.1641ZM21.4704 17.5072C22.4812 17.5072 23.2622 17.1405 23.8288 16.392C24.3954 15.6434 24.6864 14.6504 24.6864 13.3977C24.6864 12.145 24.3954 11.152 23.8288 10.3881C23.2622 9.63957 22.4812 9.25764 21.4704 9.25764C20.4444 9.25764 19.6634 9.62429 19.0967 10.3729C18.5301 11.1214 18.2545 12.1297 18.2545 13.3824C18.2545 14.6351 18.5301 15.6281 19.0967 16.3767C19.6634 17.1405 20.4597 17.5072 21.4704 17.5072Z"
        fill="black"
      />
      <path
        d="M29.5869 5.10267V2.1084H32.9407V5.11795H29.5869V5.10267ZM29.5869 19.6921V7.07339H32.9407V19.6921H29.5869Z"
        fill="black"
      />
      <path
        d="M38.3466 7.0734V8.79969H38.4231C39.296 7.42476 40.5518 6.7373 42.1751 6.7373C43.4768 6.7373 44.5335 7.18033 45.3298 8.05112C46.1415 8.9219 46.5396 10.0677 46.5396 11.4579V19.7074H43.1858V11.9467C43.1858 11.2593 42.9867 10.694 42.6039 10.2663C42.221 9.83851 41.685 9.62464 40.9806 9.62464C40.2455 9.62464 39.633 9.89962 39.1429 10.4343C38.6528 10.969 38.4078 11.6717 38.4078 12.5272V19.6921H35.0693V7.0734H38.3466Z"
        fill="black"
      />
      <path
        d="M54.4417 20.0737C52.4815 20.0737 50.9194 19.4473 49.7249 18.1793C48.5457 16.9114 47.9485 15.3226 47.9485 13.3977C47.9485 11.4728 48.5304 9.884 49.6943 8.61601C50.8429 7.36331 52.3743 6.72168 54.2579 6.72168C55.8506 6.72168 57.1523 7.13416 58.163 7.97438C59.1738 8.81461 59.7863 9.92983 60.016 11.3506H56.7388C56.6163 10.7701 56.3559 10.3117 55.9425 9.9451C55.529 9.57846 55.0236 9.41041 54.4111 9.41041C53.4309 9.41041 52.6652 9.76178 52.1446 10.4645C51.6239 11.1673 51.3482 12.145 51.3482 13.3977C51.3482 14.6351 51.6086 15.5976 52.1139 16.3156C52.6193 17.0336 53.3697 17.3849 54.3651 17.3849C55.8353 17.3849 56.6775 16.6669 56.9073 15.2462H60.1385C60.016 16.6364 59.4494 17.7974 58.4387 18.6988C57.4126 19.6154 56.0803 20.0737 54.4417 20.0737Z"
        fill="black"
      />
      <path
        d="M64.9012 2.1084V8.72329H64.9778C65.4525 8.03583 65.9732 7.53169 66.5398 7.21088C67.1064 6.89007 67.8109 6.7373 68.6532 6.7373C69.9549 6.7373 71.0115 7.18033 71.8079 8.05111C72.6195 8.92189 73.0177 10.0677 73.0177 11.4579V19.7074H69.6639V11.9467C69.6639 11.2593 69.4648 10.694 69.082 10.2663C68.6991 9.8385 68.1631 9.62463 67.4587 9.62463C66.7236 9.62463 66.111 9.89961 65.621 10.4343C65.1309 10.969 64.8859 11.6717 64.8859 12.5272V19.6921H61.5321V2.1084H64.9012Z"
        fill="black"
      />
      <path
        d="M83.0025 19.6925C82.8341 19.4786 82.7116 19.005 82.6044 18.287H82.5584C82.1909 18.8217 81.7315 19.2494 81.1802 19.5397C80.6135 19.8299 79.8325 19.9827 78.8371 19.9827C77.5048 19.9827 76.4328 19.6466 75.6364 18.9744C74.8248 18.3023 74.4266 17.3551 74.4266 16.1177C74.4266 14.8344 74.8707 13.9025 75.759 13.3067C76.6472 12.7109 77.9029 12.2984 79.5109 12.0693C80.6901 11.9012 81.5018 11.7332 81.9305 11.5652C82.3593 11.3971 82.5737 11.0916 82.5737 10.6485C82.5737 10.1902 82.39 9.82359 82.0378 9.5486C81.6702 9.27362 81.1495 9.13612 80.4604 9.13612C78.929 9.13612 78.1173 9.73192 78.0101 10.9082H75.0239C75.0698 9.71665 75.5599 8.72365 76.4787 7.92925C77.3976 7.13485 78.7299 6.75293 80.4757 6.75293C84.0286 6.75293 85.805 8.2959 85.805 11.3971V17.8134C85.805 18.7606 85.9582 19.3411 86.2491 19.555V19.6772H83.0025V19.6925ZM79.6487 17.6606C80.5676 17.6606 81.3027 17.4162 81.8387 16.9426C82.3747 16.469 82.635 15.9038 82.635 15.2469V13.3525C82.2675 13.5664 81.4864 13.8109 80.2919 14.0858C79.3425 14.2997 78.6686 14.5441 78.2705 14.8191C77.8723 15.0941 77.6732 15.5066 77.6732 16.0566C77.6732 17.1259 78.3317 17.6606 79.6487 17.6606Z"
        fill="black"
      />
      <path
        d="M91.1497 7.0734V8.79969H91.2263C92.0992 7.42476 93.3549 6.7373 94.9782 6.7373C96.2799 6.7373 97.3366 7.18033 98.1329 8.05112C98.9446 8.9219 99.3428 10.0677 99.3428 11.4579V19.7074H95.989V11.9467C95.989 11.2593 95.7899 10.694 95.407 10.2663C95.0242 9.83851 94.4882 9.62464 93.7837 9.62464C93.0487 9.62464 92.4361 9.89962 91.946 10.4343C91.456 10.969 91.211 11.6717 91.211 12.5272V19.6921H87.8572V7.0734H91.1497Z"
        fill="black"
      />
      <path
        d="M106.939 24.0002C105.223 24.0002 103.86 23.6488 102.834 22.9614C101.808 22.2739 101.196 21.3115 101.027 20.0588H104.366C104.687 20.9906 105.545 21.4642 106.908 21.4642C108.776 21.4642 109.726 20.5934 109.726 18.8366V17.4617H109.649C108.776 18.4394 107.674 18.9435 106.341 18.9435C104.841 18.9435 103.539 18.3936 102.436 17.2936C101.334 16.1937 100.767 14.7271 100.767 12.9092C100.767 11.0454 101.272 9.54825 102.298 8.41776C103.324 7.28727 104.626 6.7373 106.249 6.7373C107.781 6.7373 108.945 7.30255 109.756 8.41776H109.802V7.08867H113.034V18.7602C113.034 20.3948 112.528 21.6781 111.533 22.5947C110.491 23.5266 108.96 24.0002 106.939 24.0002ZM106.893 16.2548C107.888 16.2548 108.638 15.934 109.144 15.2924C109.634 14.6507 109.879 13.8258 109.879 12.8328C109.879 11.8551 109.619 11.0301 109.098 10.3885C108.577 9.74685 107.842 9.41076 106.862 9.41076C106.004 9.41076 105.315 9.7163 104.795 10.3427C104.274 10.969 104.014 11.8092 104.014 12.8481C104.014 13.9174 104.274 14.7424 104.795 15.3535C105.331 15.9493 106.02 16.2548 106.893 16.2548Z"
        fill="black"
      />
      <path
        d="M121.073 20.074C119.037 20.074 117.429 19.4477 116.265 18.195C115.101 16.9423 114.519 15.3382 114.519 13.3828C114.519 11.5037 115.101 9.9149 116.265 8.64692C117.429 7.37893 118.93 6.7373 120.752 6.7373C122.743 6.7373 124.274 7.42476 125.361 8.79969C126.449 10.1746 126.985 12.0231 126.985 14.3299H117.812C117.949 15.3382 118.286 16.102 118.822 16.652C119.358 17.202 120.109 17.477 121.043 17.477C122.283 17.477 123.095 16.9575 123.493 15.934H126.801C126.556 17.1103 125.928 18.1033 124.933 18.8824C123.922 19.6768 122.636 20.074 121.073 20.074ZM120.813 9.3191C119.19 9.3191 118.21 10.2204 117.873 12.0231H123.493C123.447 11.2134 123.172 10.5718 122.681 10.0677C122.191 9.56353 121.564 9.3191 120.813 9.3191Z"
        fill="black"
      />
      <path
        d="M1.97552 5.72884C2.11335 5.57607 2.25118 5.4233 2.40432 5.27053C4.04293 3.6359 6.20222 2.74984 8.49934 2.74984C10.7965 2.74984 12.9557 3.6359 14.5944 5.27053C14.7475 5.4233 14.8853 5.57607 15.0078 5.72884L17.014 3.81922C16.8608 3.65118 16.7077 3.48313 16.5393 3.31509C14.3953 1.17632 11.5315 0 8.49934 0C5.46714 0 2.6034 1.17632 0.459424 3.31509C0.306282 3.46786 0.153141 3.6359 0 3.80395L1.97552 5.72884Z"
        fill="#FF7100"
      />
    </g>
    <defs>
      <clipPath id="clip0_15692_141385">
        <rect width="127" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
