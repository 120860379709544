import { MessageType } from 'entities/types';
import { ComponentProps } from 'react';

import { Danger } from './components/Danger';
import Info from './components/Info';
import Success from './components/Success';
import Warning from './components/Warning';

const messages = {
  [MessageType.WARNING]: Warning,
  [MessageType.INFO]: Info,
  [MessageType.SUCCESS]: Success,
  [MessageType.DANGER]: Danger,
};

export type MessageIconProps = {
  type: MessageType;
} & ComponentProps<'svg'>;

export const MessageIcon = ({ type, ...props }: MessageIconProps) => {
  const Icon = messages[type];
  return <Icon {...props} />;
};
