import { ComponentProps } from 'react';

type Props = {
  large?: boolean;
} & ComponentProps<'svg'>;

export const MetaMaskIcon = ({ large, ...props }: Props) => {
  if (large) {
    return (
      <svg
        width="45"
        height="42"
        viewBox="0 0 45 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2075 37.9884L18.8975 39.5662V37.503L19.362 37.0176H22.6133V39.4447V41.1437H19.1297L14.8332 39.202L13.2075 37.9884Z"
          fill="#CDBDB2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7866 37.9884L26.2129 39.5662V37.503L25.7484 37.0176H22.497V39.4447V41.1437H25.9806L30.277 39.202L31.7866 37.9884Z"
          fill="#CDBDB2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3619 33.4995L18.8975 37.5041L19.478 37.0187H25.5162L26.2129 37.5041L25.7484 33.4995L24.8195 32.8926L20.1747 33.014L19.3619 33.4995Z"
          fill="#393939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2266 6.43555L19.0134 13.2315L20.2909 33.0128H24.8194L26.2129 13.2315L28.7675 6.43555H16.2266Z"
          fill="#F89C35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.03407 21.3634L0.782715 31.1932L8.9111 30.7077H14.1364V26.4604L13.9041 17.7227L12.7429 18.6935L4.03407 21.3634Z"
          fill="#F89D35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1882 22.4551L19.7101 22.6978L18.6651 27.7948L14.1363 26.5812L10.1882 22.4551Z"
          fill="#D87C30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1882 22.5762L14.1363 26.4596V30.343L10.1882 22.5762Z"
          fill="#EA8D3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1365 26.582L18.7813 27.7956L20.2909 33.0141L19.2457 33.6207L14.1365 30.4655V26.582Z"
          fill="#F89D35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1365 30.4648L13.2075 37.989L19.362 33.4989L14.1365 30.4648Z"
          fill="#EB8F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.71 22.6973L20.2907 33.0127L18.5488 27.7336L19.71 22.6973Z"
          fill="#EA8E3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.79492 30.5863L14.1364 30.4648L13.2075 37.989L8.79492 30.5863Z"
          fill="#D87C30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33735 41.2654L13.2074 37.9886L8.79487 30.5859L0.782715 31.1926L3.33735 41.2654Z"
          fill="#EB8F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0133 13.2324L14.0203 17.6013L10.1882 22.4556L19.7101 22.8197L19.0133 13.2324Z"
          fill="#E8821E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2075 37.9881L19.362 33.498L18.8975 37.3815V39.5659L14.7172 38.7163L13.2075 37.9881Z"
          fill="#DFCEC3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7866 37.9881L25.7484 33.498L26.2129 37.3815V39.5659L30.3932 38.7163L31.7866 37.9881Z"
          fill="#DFCEC3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1554 24.8828L18.4329 27.6741L13.9041 26.4606L17.1554 24.8828Z"
          fill="#393939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.22119 0.732422L19.0135 13.2321L16.3428 6.43609L3.22119 0.732422Z"
          fill="#E88F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.22101 0.732422L1.13086 7.40706L2.29206 14.6885L1.47922 15.1739L2.64042 16.2661L1.71146 17.1156L2.98878 18.3292L2.17594 19.0573L4.03385 21.4845L12.7428 18.6933C17.0006 15.1334 19.0907 13.3131 19.0133 13.2322C18.9359 13.1513 13.6718 8.9847 3.22101 0.732422Z"
          fill="#8E5A30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.9598 21.3634L44.2112 31.1932L36.0828 30.7077H30.8575V26.4604L31.0898 17.7227L32.2509 18.6935L40.9598 21.3634Z"
          fill="#F89D35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.8057 22.4551L25.2839 22.6978L26.3288 27.7948L30.8576 26.5812L34.8057 22.4551Z"
          fill="#D87C30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.8057 22.5762L30.8576 26.4596V30.343L34.8057 22.5762Z"
          fill="#EA8D3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8574 26.582L26.2126 27.7956L24.703 33.0141L25.7481 33.6207L30.8574 30.4655V26.582Z"
          fill="#F89D35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8574 30.4648L31.7864 37.989L25.7481 33.6201L30.8574 30.4648Z"
          fill="#EB8F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2839 22.6973L24.7032 33.0127L26.4451 27.7336L25.2839 22.6973Z"
          fill="#EA8E3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.199 30.5863L30.8575 30.4648L31.7864 37.989L36.199 30.5863Z"
          fill="#D87C30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6565 41.2654L31.7865 37.9886L36.199 30.5859L44.2112 31.1926L41.6565 41.2654Z"
          fill="#EB8F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9806 13.2324L30.9736 17.6013L34.8057 22.4556L25.2839 22.8197L25.9806 13.2324Z"
          fill="#E8821E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.8385 24.8828L26.561 27.6741L31.0898 26.4606L27.8385 24.8828Z"
          fill="#393939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.7727 0.732422L25.9804 13.2321L28.6511 6.43609L41.7727 0.732422Z"
          fill="#E88F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.7729 0.732422L43.863 7.40706L42.7018 14.6885L43.5147 15.1739L42.3535 16.2661L43.2824 17.1156L42.0051 18.3292L42.818 19.0573L40.96 21.4845L32.2511 18.6933C27.9933 15.1334 25.9032 13.3131 25.9806 13.2322C26.058 13.1513 31.3221 8.9847 41.7729 0.732422Z"
          fill="#8E5A30"
        />
      </svg>
    );
  }
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35376 19.4952L9.19874 20.2841V19.2525L9.43098 19.0098H11.0567V20.2233V21.0728H9.31486L7.1666 20.102L6.35376 19.4952Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6433 19.4952L12.8564 20.2841V19.2525L12.6242 19.0098H10.9985V20.2233V21.0728H12.7403L14.8885 20.102L15.6433 19.4952Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43097 17.2497L9.19873 19.2521L9.48898 19.0094H12.5081L12.8564 19.2521L12.6242 17.2497L12.1597 16.9463L9.83734 17.007L9.43097 17.2497Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86328 3.71875L9.25672 7.11674L9.89543 17.0074H12.1597L12.8564 7.11674L14.1337 3.71875H7.86328Z"
        fill="#F89C35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76703 11.1822L0.141357 16.0971L4.20555 15.8544H6.81819V13.7307L6.70207 9.36182L6.12147 9.84724L1.76703 11.1822Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84424 11.728L9.60515 11.8494L9.08266 14.3979L6.81827 13.7911L4.84424 11.728Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84424 11.7891L6.81828 13.7308V15.6725L4.84424 11.7891Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81812 13.791L9.14051 14.3978L9.89534 17.007L9.37275 17.3104L6.81812 15.7327V13.791Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81824 15.7329L6.35376 19.495L9.43099 17.2499L6.81824 15.7329Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60501 11.8496L9.89536 17.0073L9.02441 14.3678L9.60501 11.8496Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14746 15.7936L6.81821 15.7329L6.35374 19.495L4.14746 15.7936Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41867 21.1332L6.35371 19.4948L4.14744 15.7935L0.141357 16.0968L1.41867 21.1332Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25679 7.1167L6.76027 9.30113L4.84424 11.7283L9.60515 11.9104L9.25679 7.1167Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35376 19.495L9.43099 17.25L9.19875 19.1917V20.2839L7.10859 19.8591L6.35376 19.495Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6433 19.495L12.6242 17.25L12.8564 19.1917V20.2839L14.9466 19.8591L15.6433 19.495Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32784 12.9419L8.96655 14.3376L6.70216 13.7308L8.32784 12.9419Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3606 0.866699L9.25674 7.11653L7.92141 3.71853L1.3606 0.866699Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36051 0.866699L0.31543 4.20402L0.896028 7.84472L0.489609 8.08744L1.07021 8.63354L0.605729 9.05829L1.24439 9.66508L0.837968 10.0292L1.76693 11.2427L6.12142 9.84711C8.25028 8.06721 9.29536 7.15704 9.25665 7.11658C9.21794 7.07613 6.5859 4.99284 1.36051 0.866699Z"
        fill="#8E5A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.23 11.1822L21.8557 16.0971L17.7915 15.8544H15.1789V13.7307L15.295 9.36182L15.8756 9.84724L20.23 11.1822Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1528 11.728L12.3919 11.8494L12.9144 14.3979L15.1788 13.7911L17.1528 11.728Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1528 11.7891L15.1788 13.7308V15.6725L17.1528 11.7891Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.179 13.791L12.8566 14.3978L12.1017 17.007L12.6243 17.3104L15.179 15.7327V13.791Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1788 15.7329L15.6433 19.495L12.6242 17.3106L15.1788 15.7329Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3921 11.8496L12.1017 17.0073L12.9727 14.3678L12.3921 11.8496Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8496 15.7936L15.1789 15.7329L15.6433 19.495L17.8496 15.7936Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5784 21.1332L15.6434 19.4948L17.8496 15.7935L21.8557 16.0968L20.5784 21.1332Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7403 7.1167L15.2368 9.30113L17.1528 11.7283L12.3919 11.9104L12.7403 7.1167Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6692 12.9419L13.0305 14.3376L15.2949 13.7308L13.6692 12.9419Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6365 0.866699L12.7403 7.11653L14.0757 3.71853L20.6365 0.866699Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6366 0.866699L21.6816 4.20402L21.101 7.84472L21.5075 8.08744L20.9269 8.63354L21.3913 9.05829L20.7527 9.66508L21.1591 10.0292L20.2301 11.2427L15.8757 9.84711C13.7468 8.06721 12.7017 7.15704 12.7404 7.11658C12.7791 7.07613 15.4112 4.99284 20.6366 0.866699Z"
        fill="#8E5A30"
      />
    </svg>
  );
};
