import cx from 'classnames';
import { MessageType } from 'entities/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOutsideClick } from 'shared/lib/hooks/useOutsideClick';
import { CloseIcon } from 'shared/ui/atoms/icons/CloseIcon';
import { MessageIcon } from 'shared/ui/atoms/icons/MessageIcon';

import { DismissWay, Message } from './components/SnackbarContainer';
import { SnackbarTypes } from './sendMessage';
import {
  AFTER_MOUSE_LEAVE_TIMEOUT,
  BEFORE_ACTIVITY_TIMEOUT,
  HIDE_SNACKBAR_TIMEOUT,
} from './settings';

import css from './index.module.css';

const iconTypes = {
  [SnackbarTypes.ERROR]: MessageType.DANGER,
  [SnackbarTypes.WARNING]: MessageType.DANGER,
  [SnackbarTypes.ALERT]: MessageType.WARNING,
  [SnackbarTypes.INFO]: MessageType.INFO,
  [SnackbarTypes.SUCCESS]: MessageType.SUCCESS,
};

export type SnackbarProps = {
  type: SnackbarTypes;
  message: Message;
  count: number;
  dismiss?: DismissWay[];
  dismissTitle?: string;
  actions?: {
    title: string;
    onAction: () => void;
    closeSnackbar?: boolean;
  }[];
  onDismiss?: () => void;
  deleteThis: () => void;
};

export const Snackbar = ({
  type,
  message,
  count,
  dismiss,
  dismissTitle,
  actions,
  onDismiss,
  deleteThis,
}: SnackbarProps) => {
  const [hidden, setHidden] = useState(false);
  const [isActivitityAvailable, setIsActivityAvailable] = useState(false);

  const activityTimeout = useRef<any>();
  const baseTimeout = useRef<any>();
  const userTimeout = useRef<any>();

  const isBaseTimeout = useRef(true);
  const isUserTimeout = useRef(false);

  const isManual = dismiss?.includes('manual');
  const isAuto = dismiss?.includes('auto');
  const isActivity = dismiss?.includes('activity');
  const isAll = !dismiss;

  const closeMessage = useCallback(() => {
    clearTimeout(activityTimeout.current);
    clearTimeout(baseTimeout.current);
    clearTimeout(userTimeout.current);

    setHidden(true);
    setTimeout(() => {
      deleteThis();
    }, 360);
  }, [deleteThis]);

  const dismissMessage = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
    closeMessage();
  }, [closeMessage, onDismiss]);

  const ref = useOutsideClick<HTMLDivElement>({
    onOutsideClick: dismissMessage,
    watch: isActivitityAvailable,
  });

  const dismissByTimeout = useCallback(() => {
    if (!isBaseTimeout.current && !isUserTimeout.current) {
      dismissMessage();
    }
  }, [dismissMessage]);

  const onMouseEnter = () => {
    if (!(isAll || isAuto)) {
      return;
    }

    isUserTimeout.current = true;

    clearTimeout(userTimeout.current);
  };

  const onMouseLeave = () => {
    if (!(isAll || isAuto)) {
      return;
    }

    userTimeout.current = setTimeout(() => {
      isUserTimeout.current = false;
      dismissByTimeout();
    }, AFTER_MOUSE_LEAVE_TIMEOUT);
  };

  useEffect(() => {
    if (isAll || isActivity) {
      activityTimeout.current = setTimeout(() => {
        setIsActivityAvailable(true);
      }, BEFORE_ACTIVITY_TIMEOUT);

      window.addEventListener('keydown', dismissMessage);
    }

    if (isAll || isAuto) {
      baseTimeout.current = setTimeout(() => {
        isBaseTimeout.current = false;
        dismissByTimeout();
      }, HIDE_SNACKBAR_TIMEOUT);
    }

    return () => {
      clearTimeout(baseTimeout.current);
      clearTimeout(activityTimeout.current);
      window.removeEventListener('keydown', dismissMessage);
    };
  }, [count, dismissByTimeout, dismissMessage, isActivity, isAll, isAuto]);

  const onActionClick = (onAction: () => void, close = true) => {
    onAction();
    if (close) {
      closeMessage();
    }
  };

  return (
    <div className={cx(css.wrapper, { [css.wrapper_hidden]: hidden })} ref={ref}>
      <div
        className={cx(css.snack, 'body', {
          [css.snack_hidden]: hidden,
          [css.snack_center]: typeof message === 'string',
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={cx(css.content, { [css.content_center]: typeof message === 'string' })}>
          <MessageIcon type={iconTypes[type]} className={css.icon} />
          {typeof message === 'string' ? (
            <p className="body">{message}</p>
          ) : (
            <div>
              <p className="body">{message.title}</p>
              <p className="body">{message.description}</p>
            </div>
          )}
        </div>
        <div className={cx(css.actions, { [css.actions_filled]: actions?.length })}>
          {actions?.map((action, i) => (
            <button
              className={cx(css.action, 'body')}
              onClick={() => onActionClick(action.onAction, action.closeSnackbar)}
              key={`${action.title}::${new Date()}`}
            >
              {action.title}
            </button>
          ))}
          {(isManual || isAll) &&
            (actions?.length ? (
              <button onClick={dismissMessage} className={css.cross}>
                <CloseIcon color="white" size="m" />
              </button>
            ) : (
              <button className={css.action} onClick={dismissMessage}>
                {dismissTitle || 'Dismiss'}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};
