import cx from 'classnames';
import { MetaMaskIcon } from 'shared/ui/atoms/icons/MetaMaskIcon';
import { WalletConnectIcon } from 'shared/ui/atoms/icons/WalletConnectIcon';
import { WALLETCONNECT_PROJECT } from 'shared/lib/constants/global';

import { WalletCard } from './components/WalletCard';

import css from './index.module.css';

type Props = {
  metaMaskHander: () => void;
  walletConnectHandler: () => void;
  onClose: () => void;
};

export const WalletChoice = ({ metaMaskHander, onClose, walletConnectHandler }: Props) => (
  <div className={css.wrapper}>
    <h3 className={cx(css.title, 'title1')}>Select Your Wallet</h3>
    <div className={css.wallets}>
      <WalletCard
        className={css.card}
        title="Metamask"
        icon={<MetaMaskIcon large />}
        onClick={() => {
          metaMaskHander();
          onClose();
        }}
      />
      {WALLETCONNECT_PROJECT ? (
        <WalletCard
          className={css.card}
          title="WalletConnect"
          icon={<WalletConnectIcon large />}
          onClick={() => {
            walletConnectHandler();
            onClose();
          }}
        />
      ) : null}
    </div>
  </div>
);
