import { ReactNode } from 'react';
import { Footer } from 'widgets/Footer';
import { Header } from 'widgets/Header';

import css from './index.module.css';

type Props = {
  header?: boolean;
  footer?: boolean;
  children: ReactNode;
};

export const PageTemplate = ({ header = true, footer = true, children }: Props) => (
  <>
    {header ? <Header /> : null}
    <main className={css.container}>{children}</main>
    {footer ? <Footer /> : null}
  </>
);
