import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  dotsColor?: string;
} & ComponentProps<'div'>;

export const DotsLoader = ({ className, dotsColor = '#969696' }: Props) => (
  <div
    className={cx(css.wrapper, className, {
      [css.initial]: !className,
    })}
  >
    <div
      style={{ backgroundColor: `${dotsColor}` }}
      className={cx(css['pulse-bubble'], css['pulse-bubble-1'])}
    />
    <div
      style={{ backgroundColor: `${dotsColor}` }}
      className={cx(css['pulse-bubble'], css['pulse-bubble-2'])}
    />
    <div
      style={{ backgroundColor: `${dotsColor}` }}
      className={cx(css['pulse-bubble'], css['pulse-bubble-3'])}
    />
  </div>
);
