import { ComponentProps } from 'react';

export const MarksDownIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.19284 11.2225L11.5376 13.7903C11.793 14.0699 12.207 14.0699 12.4624 13.7903L14.8071 11.2225C15.2191 10.7714 14.9274 10 14.3448 10H9.65522C9.07265 10 8.7809 10.7714 9.19284 11.2225Z"
      fill="currentColor"
    />
  </svg>
);
