import { Blockchain, ChainIds } from './chains';

export enum Connection {
  MetaMask = 'metamask',
  WalletConnect = 'walletconnect',
}

export type WalletType = {
  address: string;
  connection: Connection;
};

//Metamask
export type MetaMaskErrorType = {
  code: number;
  message: string;
  stack: string;
};

export type ChainDataType = {
  id: number;
  name: string;
  network: string;
  [key: string]: any;
};

//Chains
export type ChainParamsType = {
  chainId: string;
  chainName: string;
  nativeCurrency: any;
  rpcUrls: string[];
  blockExplorerUrls?: string[];
};

export type ChainType = {
  [key: string]: ChainDataType;
};

export type ChainsItemType = {
  label: string;
  value: ChainIds;
  icon?: Blockchain;
  shortName?: string;
};

//Wallet Connect
export type WalletConnectAccountType = {
  address: string;
  isConnected: boolean;
};
