import cx from 'classnames';

import css from './index.module.css';

export const Unauthorized = () => (
  <section className={css.wrapper}>
    <div className={cx('content', css.inner)}>
      <img
        src="/images/common/CoinchangeShield.png"
        alt="Coinchange Shield"
        width={202}
        height={157}
        loading="lazy"
        srcSet="/images/common/CoinchangeShield.png 1x, /images/common/CoinchangeShield@2x.png 2x"
      />
      <h1 className={css.title}>Connect Your Wallet</h1>
    </div>
  </section>
);
