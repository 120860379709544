import cx from 'classnames';
import { ReactNode } from 'react';
import { Modal as ModalComponent } from 'react-responsive-modal';

import css from './index.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  center?: boolean;
  closeIcon?: ReactNode;
  isShowCloseBtn?: boolean;
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  className,
  center = true,
  closeIcon,
  isShowCloseBtn = false,
}: Props) => (
  <ModalComponent
    open={isOpen}
    onClose={onClose}
    closeIcon={closeIcon}
    showCloseIcon={isShowCloseBtn}
    center={center}
    classNames={{
      modal: cx(css.modal, className),
      overlay: css.overlay,
    }}
    animationDuration={0}
  >
    {children}
  </ModalComponent>
);
