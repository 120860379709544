import { ComponentProps } from 'react';

export const Arbitrum = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill="#2E3449" />
    <path
      d="M7.80762 12.0798V19.9198C7.80762 20.4238 8.07162 20.8798 8.51162 21.1358L15.3036 25.0558C15.7356 25.3038 16.2716 25.3038 16.7036 25.0558L23.4956 21.1358C23.9276 20.8878 24.1996 20.4238 24.1996 19.9198V12.0798C24.1996 11.5758 23.9356 11.1198 23.4956 10.8638L16.7036 6.94381C16.2716 6.69581 15.7356 6.69581 15.3036 6.94381L8.51162 10.8638C8.07962 11.1118 7.81562 11.5758 7.81562 12.0798H7.80762Z"
      fill="#213147"
    />
    <path
      d="M17.4797 17.5201L16.5117 20.1761C16.4877 20.2481 16.4877 20.3281 16.5117 20.4081L18.1757 24.9761L20.1037 23.8641L17.7917 17.5201C17.7357 17.3761 17.5357 17.3761 17.4797 17.5201Z"
      fill="#12AAFF"
    />
    <path
      d="M19.4235 13.0553C19.3675 12.9113 19.1675 12.9113 19.1115 13.0553L18.1435 15.7113C18.1195 15.7833 18.1195 15.8633 18.1435 15.9433L20.8715 23.4233L22.7995 22.3113L19.4235 13.0633V13.0553Z"
      fill="#12AAFF"
    />
    <path
      d="M15.9997 7.24C16.0477 7.24 16.0957 7.256 16.1357 7.28L23.4797 11.52C23.5677 11.568 23.6157 11.664 23.6157 11.76V20.24C23.6157 20.336 23.5597 20.432 23.4797 20.48L16.1357 24.72C16.0957 24.744 16.0477 24.76 15.9997 24.76C15.9517 24.76 15.9037 24.744 15.8637 24.72L8.51974 20.48C8.43174 20.432 8.38374 20.336 8.38374 20.24V11.752C8.38374 11.656 8.43974 11.56 8.51974 11.512L15.8637 7.272C15.9037 7.248 15.9517 7.232 15.9997 7.232V7.24ZM15.9997 6C15.7357 6 15.4797 6.064 15.2397 6.2L7.89574 10.44C7.42374 10.712 7.13574 11.208 7.13574 11.752V20.232C7.13574 20.776 7.42374 21.272 7.89574 21.544L15.2397 25.784C15.4717 25.92 15.7357 25.984 15.9997 25.984C16.2637 25.984 16.5197 25.92 16.7597 25.784L24.1037 21.544C24.5757 21.272 24.8637 20.776 24.8637 20.232V11.752C24.8637 11.208 24.5757 10.712 24.1037 10.44L16.7517 6.2C16.5197 6.064 16.2557 6 15.9917 6H15.9997Z"
      fill="#9DCCED"
    />
    <path
      d="M11.1357 23.4322L11.8157 21.5762L13.1757 22.7042L11.9037 23.8722L11.1357 23.4322Z"
      fill="#213147"
    />
    <path
      d="M15.3755 11.1525H13.5115C13.3755 11.1525 13.2475 11.2405 13.1995 11.3685L9.20752 22.3125L11.1355 23.4245L15.5355 11.3685C15.5755 11.2565 15.4955 11.1445 15.3835 11.1445L15.3755 11.1525Z"
      fill="white"
    />
    <path
      d="M18.6393 11.1525H16.7753C16.6393 11.1525 16.5113 11.2405 16.4633 11.3685L11.9033 23.8645L13.8313 24.9765L18.7913 11.3685C18.8313 11.2565 18.7513 11.1445 18.6393 11.1445V11.1525Z"
      fill="white"
    />
  </svg>
);
