import { components, ControlProps } from 'react-select';

import { SelectOptionType } from '../../../..';

import css from './index.module.css';

export const BlockchainControl = ({ children, ...props }: ControlProps<SelectOptionType>) => (
  <components.Control className={css.control} {...props}>
    {children}
  </components.Control>
);
