/* eslint-disable consistent-return */
import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Snackbar, SnackbarProps } from '../..';
import { messages, subscribe } from '../../sendMessage';

import css from './index.module.css';

export type Message =
  | string
  | {
      title: string;
      description: string;
    };

export type DismissWay = 'auto' | 'manual' | 'activity';

export type MessageItem = SnackbarProps & { key: number };

export type MessagesStore = {
  [idx: number]: MessageItem;
};

export const SnackbarContainer = () => {
  const [top, setTop] = useState(window.scrollY < 68 ? 92 - window.scrollY : 24);
  const [store, setStore] = useState<MessagesStore>(messages);

  const messagesList = Object.values(store);

  useEffect(() => {
    const updateTop = () => {
      setTop(window.scrollY < 68 ? 92 - window.scrollY : 24);
    };

    if (messagesList.length) {
      window.addEventListener('scroll', updateTop);
      return () => window.removeEventListener('scroll', updateTop);
    }
  }, [messagesList.length, setTop]);

  const update = useCallback(
    (next: MessagesStore) => {
      setStore((current) => {
        if (Object.keys(current).length) {
          return next;
        }

        if (Object.keys(next).length) {
          setTop(window.scrollY < 68 ? 92 - window.scrollY : 24);
        }

        return next;
      });
    },
    [setStore, setTop]
  );

  useEffect(() => {
    subscribe(update);
  }, [update]);

  if (!messagesList.length) {
    return null;
  }

  const maxMessages = Math.floor((document.documentElement.clientHeight - 118) / 74) || 1;
  const showMassagesList =
    messagesList.length > maxMessages ? messagesList.slice(0, maxMessages) : messagesList;

  const templateMountPoint = document.getElementById('snackbar') as HTMLElement;

  const container = (
    <div className={css.snackbar} style={{ top }}>
      {showMassagesList.map(({ type, message, key, count, deleteThis, ...props }) => (
        <Snackbar
          {...props}
          deleteThis={deleteThis}
          count={count}
          type={type}
          message={message}
          key={key}
        />
      ))}
    </div>
  );

  if (templateMountPoint) {
    return ReactDOM.createPortal(container, templateMountPoint, 'snackbar');
  }

  return container;
};
