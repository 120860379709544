import 'react-responsive-modal/styles.css';
import './styles/fonts.css';
import './styles/global.css';
import './styles/typography.css';
import './styles/variables.css';

import { useWalletContext } from 'entities/wallet';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';
import { PageTemplate } from 'shared/ui/templates/PageTemplate';

import { ProtectedRoutes } from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

export const App = () => {
  const { isLoading, isConnected } = useWalletContext();

  if (isLoading) {
    return (
      <PageTemplate>
        <section>
          <DotsLoader />
        </section>
      </PageTemplate>
    );
  }

  if (!isConnected) {
    return <PublicRoutes />;
  }

  return <ProtectedRoutes />;
};
