import cx from 'classnames';
import { ComponentProps } from 'react';
import { FieldError } from 'react-hook-form';

import css from './index.module.css';

type Props = {
  label: string;
  register?: any;
  wrapperClassName?: string;
  error?: FieldError | undefined;
} & ComponentProps<'input'>;
export const Input = ({
  label,
  register,
  wrapperClassName,
  className,
  id,
  error,
  ...props
}: Props) => (
  <div className={cx(css.wrapper, className)}>
    <label className="headline" htmlFor={id}>
      {label}
    </label>
    <input className={cx(css.field, className, 'body')} {...props} {...register} />
    {error?.message ? <p className={cx(css.error, 'caption')}>{error.message}</p> : null}
  </div>
);
