import { Blockchain } from 'entities/wallet/chains';
import { ComponentProps, createElement } from 'react';

import { Arbitrum } from './blockchain/Arbitrum';
import { Avalanche } from './blockchain/Avalanche';
import { BSC } from './blockchain/BSC';

const icons: {
  [key in Blockchain]: (props: ComponentProps<'svg'>) => JSX.Element;
} = {
  Avalanche,
  Arbitrum,
  BSC,
};

type Props = {
  name: Blockchain;
} & ComponentProps<'svg'>;

export const BlockchainIcon = ({ name, ...props }: Props) => {
  if (!name || !icons[name]) {
    return null;
  }

  return createElement(icons[name], { ...props });
};
