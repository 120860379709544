import { ComponentProps } from 'react';
import cx from 'classnames';

import css from './index.module.css';

type Props = {
  variant: 'primary' | 'secondary';
} & ComponentProps<'button'>;

export const Button = ({ variant, className, children, ...props }: Props) => (
  <button
    className={cx(className, css.btn, 'headline', {
      [css.primary]: variant === 'primary',
      [css.secondary]: variant === 'secondary',
    })}
    {...props}
  >
    {children}
  </button>
);
