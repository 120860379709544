import { BaseSelect, SelectOptionType, SelectPropsType } from '../..';
import { DropdownIndicator } from '../DropdownIndicator';

import { BlockchainControl, BlockchainLabel, BlockchainMenu, BlockchainOption } from './components';

type Props = SelectPropsType;

export const BlockchainSelect = ({ name, className, defaultValue, onChange, options }: Props) => {
  const components = {
    DropdownIndicator,
    IndicatorSeparator: () => null,
    Control: BlockchainControl,
    Menu: BlockchainMenu,
    Option: BlockchainOption,
  };

  const renderLabel = (data: SelectOptionType) => (
    <BlockchainLabel name={data?.label} icon={data.icon} />
  );

  return (
    <BaseSelect
      options={options}
      className={className}
      defaultValue={defaultValue}
      onChange={onChange}
      name={name}
      components={components}
      customLabel={renderLabel}
    />
  );
};
