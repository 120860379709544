import { ComponentProps } from 'react';

const colors = {
  black: '#2A2A2A',
  gray: '#969696',
  white: '#ffffff',
};

const sizes = {
  s: '16',
  m: '24',
  l: '32',
};

type CloseIconPropsType = {
  color?: 'black' | 'gray' | 'white';
  size?: 's' | 'm' | 'l';
} & ComponentProps<'svg'>;

export const CloseIcon = ({ color = 'gray', size = 'm', ...props }: CloseIconPropsType) => {
  const computedSize = sizes[size];

  return (
    <svg
      width={computedSize}
      height={computedSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 6L6 18"
        stroke={colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
