import { WalletContextProvider } from 'entities/wallet';
import { wagmiConfig } from 'entities/wallet/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarContainer } from 'shared/ui/molecules/Snackbar/components/SnackbarContainer';
import { WagmiConfig } from 'wagmi';

import { App } from './app/app';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <WalletContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </WalletContextProvider>
    </WagmiConfig>
    <SnackbarContainer />
  </React.StrictMode>
);

reportWebVitals();
