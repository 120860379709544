import cx from 'classnames';
import { CurrencyIsoCode } from 'entities/types';
import { useWalletContext } from 'entities/wallet';
import { formatNum } from 'shared/lib/utils/helpers';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';

import { Balances } from './components/Balances';
import { DepositForm } from './components/DepositForm';
import { WithrawForm } from './components/WithdrawForm';

import css from './index.module.css';

export const Home = () => {
  const { balance, wallet, selectedChain } = useWalletContext();

  if (!balance || !wallet?.address || !selectedChain) {
    return (
      <section>
        <div className={css.loader}>
          <DotsLoader />
        </div>
      </section>
    );
  }

  return (
    <section className={css.wrapper}>
      <div className={cx('content')}>
        <Balances
          walletBalance={formatNum(balance.wallet)}
          vaultBalance={balance.vault}
          currency={CurrencyIsoCode.USDC}
        />
        <DepositForm
          selectedChain={selectedChain}
          walletBalance={balance.wallet}
          wallet={wallet.address}
        />
        <WithrawForm
          className={css.withdraw}
          walletBalance={balance.vault}
          wallet={wallet.address}
          selectedChain={selectedChain}
        />
      </div>
    </section>
  );
};
