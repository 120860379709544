import { Unauthorized } from 'pages/Unauthorized';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageTemplate } from 'shared/ui/templates/PageTemplate';

const PublicRoutes = () => (
  <PageTemplate>
    <Routes>
      <Route path="/signin" element={<Unauthorized />} />
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Routes>
  </PageTemplate>
);

export default PublicRoutes;
