import * as yup from 'yup';

export type DepositInputs = {
  value: number;
};

export const DepositValidationScheme = (walletBalance: number) =>
  yup.object<DepositInputs>().shape({
    value: yup
      .number()
      .typeError('Required field')
      .required('Required Field')
      .max(walletBalance, 'Insufficient funds')
      .test('Is positive?', 'The number must be greater than 0', (value) => value > 0),
  });
