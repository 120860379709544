import { ComponentProps } from 'react';

export const BSC = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6402_1140)">
      <circle cx="16" cy="16" r="16" fill="#F3BA2F" />
      <path
        d="M11.3411 14.0845L16.0025 9.42517L20.6658 14.0883L23.3765 11.3757L16.0025 4L8.62842 11.3738L11.3411 14.0845Z"
        fill="white"
      />
      <path d="M4 16L6.71168 13.2884L9.42336 16L6.71168 18.7116L4 16Z" fill="white" />
      <path
        d="M11.341 17.9155L16.0024 22.5767L20.6657 17.9136L23.3783 20.6224L23.3764 20.6243L16.0024 28L8.6283 20.6281L8.62451 20.6243L11.341 17.9155Z"
        fill="white"
      />
      <path
        d="M22.5767 16.0015L25.2883 13.2899L28 16.0015L25.2883 18.7131L22.5767 16.0015Z"
        fill="white"
      />
      <path
        d="M18.7528 15.999L16.0022 13.2466L13.9681 15.2805L13.7331 15.5137L13.2516 15.9952L13.2478 15.999L13.2516 16.0047L16.0022 18.7533L18.7528 16.0009L18.7547 15.999H18.7528Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6402_1140">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
