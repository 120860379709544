import { prepareWriteContract, readContract, writeContract, waitForTransaction } from '@wagmi/core';

import ERC20 from '../contracts/ERC20.json';
import VAULT from '../contracts/VaultV1.json';

export const getAllowance = async (
  tokenAddress: string,
  walletAddress: string,
  vaultAddress: string
) => {
  const data = await readContract({
    address: tokenAddress as `0x${string}`,
    functionName: 'allowance',
    abi: ERC20,
    args: [walletAddress, vaultAddress],
  });
  return data;
};

export const approveAmount = async (tokenAddress: string, vaultAddress: string, sum: bigint) => {
  const { request } = await prepareWriteContract({
    address: tokenAddress as `0x${string}`,
    abi: ERC20,
    functionName: 'approve',
    args: [vaultAddress, sum],
  });

  const { hash } = await writeContract(request);
  const data = await waitForTransaction({
    hash,
  });
  return data;  
};

export const sendToVault = async (vaultAddress: string, sum: bigint) => {
  const { request } = await prepareWriteContract({
    address: vaultAddress as `0x${string}`,
    abi: VAULT.abi,
    functionName: 'deposit',
    args: [sum],
  });

  const { hash } = await writeContract(request);
  const data = await waitForTransaction({
    hash,
  });
  return data;
};

export const withdrawFromVault = async (
  vaultAddress: string,
  sum: bigint,
  walletAddress: string
) => {
  const { request } = await prepareWriteContract({
    address: vaultAddress as `0x${string}`,
    abi: VAULT.abi,
    functionName: 'withdraw',
    args: [sum, walletAddress],
  });

  const { hash } = await writeContract(request);
  const data = await waitForTransaction({
    hash,
  });
  return data;
};
