import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import { ChainIds } from 'entities/wallet/chains';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  BUSD_TOKEN,
  TOKEN_UNITS,
  USDC_TOKEN,
  VAULT_BUSD,
  VAULT_CONTRACT,
} from 'shared/lib/constants/global';
import { approveAmount, getAllowance, sendToVault } from 'shared/lib/utils/contract';
import { parseNum } from 'shared/lib/utils/helpers';
import { Button } from 'shared/ui/atoms/Button';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';
import sendMessage from 'shared/ui/molecules/Snackbar/sendMessage';
import { Input } from 'shared/ui/molecules/inputs/Input';

import { DepositInputs, DepositValidationScheme } from './validation';

import css from './index.module.css';

type Props = {
  walletBalance: string;
  className?: string;
  wallet: string;
  selectedChain: ChainIds;
};

export const DepositForm = ({ walletBalance, className, wallet, selectedChain }: Props) => {
  const [serverError, setServerError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tokenAddress = selectedChain === ChainIds.BSC ? BUSD_TOKEN : USDC_TOKEN;
  const vaultAddress = selectedChain === ChainIds.BSC ? VAULT_BUSD : VAULT_CONTRACT;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<DepositInputs>({
    resolver: yupResolver(DepositValidationScheme(+walletBalance)),
  });

  const onSubmit = async (data: DepositInputs) => {
    try {
      setServerError('');
      setIsLoading(true);
      const amountBN = await parseNum(data.value.toString(), Number(TOKEN_UNITS));

      if (!amountBN) {
        setError('value', {
          message: 'Invalid value',
        });
        return;
      }
      const allowance = Number(await getAllowance(tokenAddress, wallet, vaultAddress));

      if (allowance < amountBN) {
        const receipt = await approveAmount(tokenAddress, vaultAddress, amountBN);
        if (!receipt) {
          setServerError(`Amount ${data.value} (${amountBN.toString()}) not approved`);
          return;
        }
      }

      await sendToVault(vaultAddress, amountBN);
      reset();
      sendMessage({
        success: 'Deposit completed',
      });
    } catch (error: any) {
      setServerError(error.error?.data ? error.error.data.message : error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cx(css.form, className)}>
      <Input
        register={register('value')}
        label="Deposit"
        name="value"
        id="deposit-value"
        type="number"
        error={errors.value}
        step="any"
      />
      <Button className={css.submit} variant="primary" type="submit" disabled={isLoading}>
        {!isLoading ? 'Deposit' : <DotsLoader />}
      </Button>
      {serverError ? <p className={cx('caption', css.error)}>{serverError}</p> : null}
    </form>
  );
};
