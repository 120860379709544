import cx from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { Link as NavLink } from 'react-router-dom';

import css from './index.module.css';

type Props = {
  to?: string;
  children: ReactNode;
} & ComponentProps<'a'>;

export const Link = ({ href, to, children, className, target, ...props }: Props) => {
  if (href) {
    return (
      <a href={href} className={cx('headline', css.link, className)} target={target} {...props}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <NavLink className={cx('headline', css.link, className)} to={to} target={target}>
        {children}
      </NavLink>
    );
  }

  return null;
};
