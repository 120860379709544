import { ComponentProps } from 'react';

type Props = {
  large?: boolean;
} & ComponentProps<'svg'>;

export const WalletConnectIcon = ({ large, ...props }: Props) => {
  if (large) {
    return (
      <svg
        width="42"
        height="26"
        viewBox="0 0 42 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8.67305 5.20202C15.4844 -1.46838 26.5254 -1.46838 33.3456 5.20202L34.1651 6.00388C34.5088 6.33872 34.5088 6.87623 34.1651 7.21108L31.363 9.96031C31.1956 10.1277 30.9136 10.1277 30.7462 9.96031L29.6183 8.85885C24.8688 4.20631 17.1587 4.20631 12.4092 8.85885L11.202 10.0396C11.0346 10.207 10.7526 10.207 10.5852 10.0396L7.77426 7.29038C7.43061 6.95554 7.43061 6.41803 7.77426 6.08319L8.67305 5.20202ZM39.1437 10.8767L41.6374 13.3175C41.981 13.6524 41.981 14.1899 41.6374 14.5247L30.3849 25.5481C30.0413 25.8829 29.4949 25.8829 29.1513 25.5481L21.1591 17.7234C21.071 17.6441 20.9388 17.6441 20.8507 17.7234L12.8674 25.5481C12.5237 25.8829 11.9774 25.8829 11.6338 25.5481L0.372486 14.5335C0.0288327 14.1987 0.0288327 13.6612 0.372486 13.3263L2.86618 10.8855C3.20983 10.5507 3.75615 10.5507 4.09981 10.8855L12.092 18.7103C12.1801 18.7896 12.3123 18.7896 12.4004 18.7103L20.3837 10.8855C20.7274 10.5507 21.2737 10.5507 21.6173 10.8855L29.6095 18.7103C29.6976 18.7896 29.8298 18.7896 29.9179 18.7103L37.91 10.8855C38.2449 10.5419 38.8 10.5419 39.1437 10.8767Z"
          fill="#3A99FB"
        />
      </svg>
    );
  }

  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.83652 3.1015C8.24222 -0.233703 13.7627 -0.233703 17.1728 3.1015L17.5826 3.50243C17.7544 3.66985 17.7544 3.93861 17.5826 4.10603L16.1815 5.48064C16.0978 5.56435 15.9568 5.56435 15.8731 5.48064L15.3092 4.92991C12.9344 2.60364 9.07932 2.60364 6.70459 4.92991L6.10099 5.52029C6.01728 5.604 5.8763 5.604 5.79259 5.52029L4.38713 4.14568C4.2153 3.97826 4.2153 3.7095 4.38713 3.54208L4.83652 3.1015ZM20.0718 5.93885L21.3187 7.15926C21.4905 7.32668 21.4905 7.59543 21.3187 7.76285L15.6925 13.2745C15.5206 13.442 15.2475 13.442 15.0756 13.2745L11.0796 9.36217C11.0355 9.32251 10.9694 9.32251 10.9254 9.36217L6.93369 13.2745C6.76187 13.442 6.4887 13.442 6.31688 13.2745L0.686243 7.76726C0.514416 7.59984 0.514416 7.33108 0.686243 7.16366L1.93309 5.94325C2.10492 5.77583 2.37808 5.77583 2.5499 5.94325L6.54598 9.85562C6.59004 9.89527 6.65613 9.89527 6.70018 9.85562L10.6919 5.94325C10.8637 5.77583 11.1368 5.77583 11.3087 5.94325L15.3047 9.85562C15.3488 9.89527 15.4149 9.89527 15.4589 9.85562L19.455 5.94325C19.6224 5.77143 19.9 5.77143 20.0718 5.93885Z"
        fill="#3A99FB"
      />
    </svg>
  );
};
