import { components, DropdownIndicatorProps } from 'react-select';
import { MarksDownIcon } from 'shared/ui/atoms/icons/MarksDownIcon';

import { SelectOptionType } from '../..';

export const DropdownIndicator = ({
  children,
  ...props
}: DropdownIndicatorProps<SelectOptionType>) => (
  <components.DropdownIndicator {...props}>
    <MarksDownIcon />
  </components.DropdownIndicator>
);
