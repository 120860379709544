import cx from 'classnames';
import { Chain, chainsOptions, useWalletContext } from 'entities/wallet';
import { ChainIds } from 'entities/wallet/chains';
import { Connection } from 'entities/wallet/types';
import { useState } from 'react';
import { formatWalletAddress } from 'shared/lib/utils/helpers';
import { Button } from 'shared/ui/atoms/Button';
import { BlockchainIcon } from 'shared/ui/atoms/icons/BlockchainIcon';
import { LogoIcon } from 'shared/ui/atoms/icons/LogoIcon';
import { LogoutIcon } from 'shared/ui/atoms/icons/LogoutIcon';
import { MetaMaskIcon } from 'shared/ui/atoms/icons/MetaMaskIcon';
import { WalletConnectIcon } from 'shared/ui/atoms/icons/WalletConnectIcon';
import { LogoutModal } from 'shared/ui/molecules/LogoutModal';
import { Modal } from 'shared/ui/molecules/Modal';
import { BlockchainSelect } from 'shared/ui/molecules/inputs/Select/components/BlockchainSelect';

import { WalletChoice } from './components/WalletChoice';

import css from './index.module.css';

export const Header = () => {
  const {
    wallet,
    selectedChain,
    onChainChange,
    openWalletConnect,
    logout,
    connectMetaMask,
    isLoading,
  } = useWalletContext();
  const [isOpenWalletModal, setIsOpenWalletModal] = useState<boolean>(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState<boolean>(false);

  const onCloseWalletModal = () => {
    setIsOpenWalletModal(false);
  };

  const onCloseLogoutModal = () => {
    setIsOpenLogoutModal(false);
  };

  const disconnectHandler = () => {
    logout();
    onCloseLogoutModal();
  };

  const renderWalletIcon = (type: Connection) => {
    switch (type) {
      case Connection.MetaMask:
        return (
          <div className={css.wallet_icon}>
            <MetaMaskIcon />
          </div>
        );
      case Connection.WalletConnect:
        return (
          <div className={css.wallet_icon}>
            <WalletConnectIcon />
          </div>
        );
      default:
        return null;
    }
  };

  if (!selectedChain) {
    return null;
  }

  return (
    <header className={css.wrapper}>
      <div className={cx('content', css.inner)}>
        <div className={css.brand}>
          <p className="caption">Powered by</p>
          <LogoIcon />
        </div>
        <div className={css.controls}>
          {chainsOptions?.length > 1 ? (
            <BlockchainSelect
              name="blockchain"
              onChange={(v) => onChainChange(v as ChainIds)}
              options={chainsOptions}
              defaultValue={selectedChain}
            />
          ) : (
            <div className={css.chain}>
              {chainsOptions[0]?.icon ? <BlockchainIcon name={chainsOptions[0]?.icon} /> : null}{' '}
              <span className="headline">{chainsOptions[0]?.label}</span>
            </div>
          )}
          {wallet ? (
            <div className={css.info}>
              {wallet?.connection ? renderWalletIcon(wallet?.connection) : null}
              {wallet?.address ? (
                <p className="body">{formatWalletAddress(wallet.address)}</p>
              ) : null}
              <button
                className={css.logout}
                type="button"
                onClick={() => setIsOpenLogoutModal(true)}
              >
                <LogoutIcon />
              </button>
            </div>
          ) : (
            <Button
              onClick={() => setIsOpenWalletModal(true)}
              className={css.connect}
              variant="primary"
              type="button"
              disabled={isLoading}
            >
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
      <Modal className={css.wallet_modal} isOpen={isOpenWalletModal} onClose={onCloseWalletModal}>
        <WalletChoice
          onClose={onCloseWalletModal}
          walletConnectHandler={openWalletConnect}
          metaMaskHander={() => connectMetaMask(Chain[selectedChain].metamask)}
        />
      </Modal>
      <LogoutModal
        wallet={wallet?.address || ''}
        onDisconnect={disconnectHandler}
        isOpen={isOpenLogoutModal}
        onClose={onCloseLogoutModal}
      />
    </header>
  );
};
