import { ComponentProps, ReactNode } from 'react';
import cx from 'classnames';

import css from './index.module.css';

type Props = {
  title: string;
  icon: ReactNode;
} & ComponentProps<'button'>;

export const WalletCard = ({ title, icon, type = 'button', className, ...props }: Props) => (
  <button className={cx(css.card, className)} type={type} {...props}>
    {icon ? <div className={css.icon}>{icon}</div> : null}
    <p className={cx(css.title, 'body')}>{title}</p>
  </button>
);
