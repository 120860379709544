import { Blockchain } from 'entities/wallet/chains';
import { BlockchainIcon } from 'shared/ui/atoms/icons/BlockchainIcon';

import css from './index.module.css';

type Props = {
  name: string;
  icon: Blockchain;
};

export const BlockchainLabel = ({ name, icon }: Props) => (
  <div className={css.container}>
    {icon ? <BlockchainIcon name={icon} /> : null}
    <span className="headline">{name}</span>
  </div>
);
