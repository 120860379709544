import cx from 'classnames';
import { formatWalletAddress } from 'shared/lib/utils/helpers';
import { LogoutIcon } from 'shared/ui/atoms/icons/LogoutIcon';

import { Modal } from '../Modal';

import css from './index.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  wallet: string;
  onDisconnect: () => void;
};

export const LogoutModal = ({ isOpen, onClose, className, wallet, onDisconnect }: Props) => (
  <Modal className={cx(css.modal, className)} isOpen={isOpen} onClose={onClose}>
    <div className={css.wrapper}>
      <LogoutIcon large />
      <h3 className={cx(css.title, 'title1')}>Disconnect Wallet</h3>
      <p className="body">{formatWalletAddress(wallet)}</p>
      <button className={cx(css.disconnect, 'headline')} type="button" onClick={onDisconnect}>
        Disconnect
      </button>
    </div>
  </Modal>
);
