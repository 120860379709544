import cx from 'classnames';
import { Link } from 'shared/ui/atoms/Link';
import { ArrowRightIcon } from 'shared/ui/atoms/icons/ArrowRightIcon';

import css from './index.module.css';

export const Footer = () => (
  <footer className={css.wrapper}>
    <div className={cx('content', css.inner)}>
      <Link
        href="https://www.coinchange.io/help-center/how-to-contact-us"
        target="_blank"
        className={css.link}
      >
        Contact Coinchange <ArrowRightIcon className={css.link_icon} />
      </Link>
    </div>
  </footer>
);
