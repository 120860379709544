import * as yup from 'yup';

export type WithdrawInputs = {
  value: number;
};

export const WithdrawValiadtionScheme = (walletBalance: number) =>
  yup.object<WithdrawInputs>().shape({
    value: yup
      .number()
      .typeError('Required field')
      .required('Required Field')
      .max(walletBalance, 'Insufficient funds')
      .test('Is positive?', 'The number must be greater than 0', (value) => value >= 0),
  });
