import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { CHAINS } from 'entities/wallet/chains';
import { WALLETCONNECT_PROJECT } from 'shared/lib/constants/global';
import { configureChains, createConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

const projectId = WALLETCONNECT_PROJECT;

const metaMask = new MetaMaskConnector({
  chains: CHAINS,
});

const walletConnect = w3mConnectors({ projectId, chains: CHAINS });

const { publicClient } = configureChains(CHAINS, [w3mProvider({ projectId }), publicProvider()]);
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [...walletConnect, metaMask],
  publicClient,
});

export const ethereumClient = new EthereumClient(
  {
    ...wagmiConfig,
    connectors: walletConnect,
  },
  CHAINS
);
