import cx from 'classnames';
import { CurrencyIsoCode } from 'entities/types';
import { ComponentProps } from 'react';
import { formatNum } from 'shared/lib/utils/helpers';

type Props = {
  walletBalance: string;
  vaultBalance: string;
  currency: CurrencyIsoCode;
} & ComponentProps<'div'>;

export const Balances = ({ walletBalance, vaultBalance, currency, className, ...props }: Props) => (
  <div className={cx(className)} {...props}>
    <h1>Balances</h1>
    <p className="body">
      Wallet Balance:{' '}
      <span className="headline">
        {`${formatNum(walletBalance)}`} {currency}
      </span>
    </p>
    <p className="body">
      Vault Balance:{' '}
      <span className="headline">
        {`${formatNum(vaultBalance)}`} {currency}
      </span>
    </p>
  </div>
);
