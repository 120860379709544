/* eslint-disable no-redeclare */
import { DismissWay, Message, MessagesStore } from './components/SnackbarContainer';

export enum SnackbarTypes {
  ERROR = 'error',
  WARNING = 'warning',
  ALERT = 'alert',
  INFO = 'info',
  SUCCESS = 'success',
}

type Params = {
  dismiss?: DismissWay | DismissWay[];
  onDismiss?: () => void;
  dismissTitle?: string;
  actions?: {
    title: string;
    onAction: () => void;
    closeSnackbar?: boolean;
  }[];
  [SnackbarTypes.ERROR]?: Message;
  [SnackbarTypes.WARNING]?: Message;
  [SnackbarTypes.ALERT]?: Message;
  [SnackbarTypes.INFO]?: Message;
  [SnackbarTypes.SUCCESS]?: Message;
};

let idx = 0;

export const messages: MessagesStore = {};

const snackbarTypes = [
  SnackbarTypes.ERROR,
  SnackbarTypes.ALERT,
  SnackbarTypes.INFO,
  SnackbarTypes.SUCCESS,
  SnackbarTypes.WARNING,
] as string[];

let setMessages: (next: MessagesStore) => void;

export const sendMessage = (params: Params | any): void => {
  const { dismiss, ...restParams } = params as Params;
  const dismissWay = typeof dismiss === 'string' ? [dismiss] : dismiss?.sort();

  const [type, message] = Object.entries(params).find((item) =>
    snackbarTypes.includes(item[0])
  ) as [SnackbarTypes, Message];

  if (
    messages[idx] &&
    messages[idx].type === type &&
    messages[idx].message === message &&
    messages[idx].dismiss?.join('') === dismissWay?.join('')
  ) {
    messages[idx].count += 1;
  } else {
    idx += 1;

    const key = idx;
    const deleteThis = () => {
      delete messages[key];

      if (setMessages) {
        setMessages({ ...messages });
      }
    };

    messages[idx] = {
      key: idx,
      type,
      message,
      count: 1,
      dismiss: dismissWay,
      onDismiss: params.onDismiss,
      deleteThis,
      ...restParams,
    };
  }

  if (setMessages) {
    setMessages({ ...messages });
  }
};

export const subscribe = (storeDispatch: (next: MessagesStore) => void): void => {
  setMessages = storeDispatch;
};

export default sendMessage;
