import { useWalletContext } from 'entities/wallet';
import { Home } from 'pages/Home';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';
import { PageTemplate } from 'shared/ui/templates/PageTemplate';

export const ProtectedRoutes = () => {
  const { wallet, selectedChain } = useWalletContext();

  if (!wallet || !selectedChain) {
    return (
      <PageTemplate>
        <section>
          <DotsLoader />
        </section>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </PageTemplate>
  );
};
